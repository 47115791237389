<template>
  <div>
    <b-overlay
      rounded
      :show="loading"
    >
      <b-button
        v-ripple.400="'rgba(255, 159, 67, 0.15)'"
        v-b-tooltip.hover.v-primary
        title="Reporte Luminarias Instaladas"
        variant="primary"
        class="btn-icon"
        @click="getDataMigraciones(fechaInicio, fechaFin, localidad, tecnicoAsignado)"
      >
        <feather-icon
          icon="DownloadIcon"
        />
      </b-button>
    </b-overlay>
  </div>
</template>
<script>
import {
  BButton, BOverlay, VBPopover, VBTooltip,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { getMigraciones } from '@/utils/migracionLuminarias'
import { notificacionError, notificacionEspera, notificacionInformativa } from '@/utils/mensajes'
import { formatoFecha, sumarDiasFecha } from '@/utils/fechas'

const xl = require('excel4node')

export default {
  components: {
    BButton,
    BOverlay,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
    'b-popover': VBPopover,
  },
  props: {
    fechaInicio: {
      type: String,
      default: '',
    },
    fechaFin: {
      type: String,
      default: '',
    },
    localidad: {
      type: Object,
      default: () => {},
    },
    tecnicoAsignado: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      loading: false,
      usuario: JSON.parse(localStorage.getItem('userData')),
    }
  },
  methods: {
    async getDataMigraciones(fechaInicio, fechaFin, localidad, tecnicoAsignado) {
      this.loading = true
      try {
        notificacionEspera('Descargando archivo...', 'Esto puede tomar unos minutos...')
        const datofechaInicio = new Date(fechaInicio)
        const datofechaFin = sumarDiasFecha(new Date(fechaFin), 1)
        const filter = {
          where: {
            and: [
              { companyId: this.usuario.company.id },
              { estado: 'FINALIZADO' },
              {
                'seguimiento.tipo': 'Foto Verificación',
              },
              {
                'seguimiento.fechaHora': { gte: datofechaInicio.toISOString() },
              },
              {
                'seguimiento.fechaHora': { lte: datofechaFin.toISOString() },
              },
            ],
          },
          order: ['fechaCreacion DESC'],
        }
        if (localidad && localidad.id > 0) {
          filter.where.and.push({ 'levantamientoPunto.localidad.id': localidad.id })
        }
        if (tecnicoAsignado && tecnicoAsignado.id) {
          filter.where.and.push({ 'usuario.id': tecnicoAsignado.id })
        }

        const migraciones = await getMigraciones(filter)

        await this.generarReporte(migraciones)
      } catch (error) {
        console.error(error)
      } finally {
        this.loading = false
      }
    },
    async generarReporte(migraciones) {
      try {
        const wb = new xl.Workbook()
        const ws = wb.addWorksheet('Luminarias Instaladas')

        const styleTitulo = wb.createStyle({
          font: {
            bold: true,
            size: 20,
            color: '#ffffff',
          },
          fill: {
            type: 'pattern',
            patternType: 'solid',
            fgColor: '#0070c0',
          },
          alignment: {
            horizontal: 'center',
            vertical: 'center',
            wrapText: true,
            width: 15,
          },
        })
        const styleTituloSecundario = wb.createStyle({
          font: {
            bold: true,
            size: 13,
            color: '#ffffff',
          },
          fill: {
            type: 'pattern',
            patternType: 'solid',
            fgColor: '#0070c0',
          },
          alignment: {
            horizontal: 'center',
            vertical: 'center',
            wrapText: true,
            width: 15,
          },
        })
        const styleSubtitulo = wb.createStyle({
          font: {
            bold: true,
            size: 10,
            color: '#ffffff',
          },
          fill: {
            type: 'pattern',
            patternType: 'solid',
            fgColor: '#0070c0',
          },
          alignment: {
            horizontal: 'center',
            vertical: 'center',
            wrapText: true,
            width: 15,
          },
        })
        const styleValores = wb.createStyle({
          font: {
            bold: true,
            size: 10,
            color: '#ffffff',
          },
          fill: {
            type: 'pattern',
            patternType: 'solid',
            fgColor: '#0070c0',
          },
          alignment: {
            horizontal: 'center',
            vertical: 'center',
            wrapText: true,
            width: 15,
          },
          border: {
            top: { style: 'thin', color: { auto: 1 } },
            left: { style: 'thin', color: { auto: 1 } },
            bottom: { style: 'thin', color: { auto: 1 } },
            right: { style: 'thin', color: { auto: 1 } },
          },
        })
        const styleCeldas = wb.createStyle({
          font: {
            size: 10,
          },
          alignment: {
            horizontal: 'center',
            vertical: 'center',
            wrapText: true,
          },
          border: {
            top: { style: 'thin', color: { auto: 1 } },
            left: { style: 'thin', color: { auto: 1 } },
            bottom: { style: 'thin', color: { auto: 1 } },
            right: { style: 'thin', color: { auto: 1 } },
          },
        })

        // HEADERS
        ws.cell(1, 1, 2, 8, true).string('ALUMBRADO PÚBLICO DE MIXCO, S.A').style(styleTitulo)
        ws.cell(3, 1, 3, 8, true).string('DATOS DE LUMINARIAS Y NEMAS INSTALADAS').style(styleTituloSecundario)
        ws.cell(4, 1, 4, 8, true).string('REPORTE GENERAL LUMINARIAS Y NEMAS INSTALADAS').style(styleSubtitulo)

        // TÍTULOS
        ws.cell(6, 1).string('NO.').style(styleValores)
        ws.cell(6, 2).string('Número de Poste').style(styleValores)
        ws.cell(6, 3).string('Serie de Luminaria Instalada').style(styleValores)
        ws.cell(6, 4).string('Potencia de Luminaria Instalada').style(styleValores)
        ws.cell(6, 5).string('Serie de Nema Instalada').style(styleValores)
        ws.cell(6, 6).string('Potencia de Nema Instalada').style(styleValores)
        ws.cell(6, 7).string('Técnico Encargado').style(styleValores)
        ws.cell(6, 8).string('Fecha de Sustitución').style(styleValores)

        // DATOS
        let numeroFila = 7
        // eslint-disable-next-line no-restricted-syntax
        for (const [index, migracion] of migraciones.entries()) {
          const seguimientoMigracion = migracion && migracion.seguimiento && migracion.seguimiento[0]
          const seguimientoMigracionFechaSustitucion = migracion && migracion.seguimiento && migracion.seguimiento[2]
          ws.cell(numeroFila, 1).number(index + 1).style(styleCeldas)
          ws.cell(numeroFila, 2).string(migracion.numeroPoste).style(styleCeldas)
          ws.cell(numeroFila, 3).string(seguimientoMigracion.datosLuminaria.serie).style(styleCeldas)
          ws.cell(numeroFila, 4).number(seguimientoMigracion.datosLuminaria.potencia.valor).style(styleCeldas)
          ws.cell(numeroFila, 5).string(seguimientoMigracion.datosNema.serie).style(styleCeldas)
          ws.cell(numeroFila, 6).number(seguimientoMigracion.datosNema.potencia.valor).style(styleCeldas)
          ws.cell(numeroFila, 7).string(migracion.usuario.nombre).style(styleCeldas)
          ws.cell(numeroFila, 8).string(formatoFecha(seguimientoMigracionFechaSustitucion.fechaHora)).style(styleCeldas)

          // eslint-disable-next-line no-plusplus
          numeroFila++
        }

        // ANCHO DE COLUMNAS
        ws.column(1).setWidth(8)
        ws.column(2).setWidth(15)
        ws.column(3).setWidth(24)
        ws.column(4).setWidth(23)
        ws.column(5).setWidth(20)
        ws.column(6).setWidth(20)
        ws.column(7).setWidth(30)
        ws.column(8).setWidth(18)

        // DESCARGA
        const buffer = await wb.writeToBuffer()
        const string = buffer.toString('base64')
        const linkSource = `data:application/xlsx;base64,${string}`
        const downloadLink = document.createElement('a')
        const fileName = 'REPORTE GENERAL DE LUMINARIAS INSTALADAS.xlsx'
        downloadLink.href = linkSource
        downloadLink.download = fileName
        downloadLink.click()
        notificacionInformativa('Documento Excel Descargado', 'Documento Excel Descargado Correctamente')
      } catch (error) {
        console.error(error)
        notificacionError('Inconvenientes!', 'Inconvenientes Descargando Documento Excel!')
      } finally {
        this.loading = false
      }
    },
  },
}
</script>
