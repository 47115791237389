<template>
  <div>
    <b-overlay
      opacity="0.8"
      variant="transparent"
      blur="0.8rem"
      spinner-variant="primary"
      :show="showOverlay"
    >
      <template #overlay>
        <div class="text-center">
          <feather-icon
            icon="ClockIcon"
            size="40"
            variant="primary"
            class="fade"
          />
          <p
            style="font-weight: bold; font-size: 1.2rem"
            class="text-primary"
          >
            POR FAVOR, ESPERE...
          </p>
        </div>
      </template>
      <div class="custom-search">
        <!-- advance search input -->
        <b-row>
          <b-col :cols="origenEstado === null ? 3 : 4">
            <label class="mr-1">Colonia (Zona): </label>
            <v-select
              v-model="localidad"
              label="name"
              :options="localidades"
            />
          </b-col>
          <b-col
            v-if="origenEstado === null"
            cols="2"
          >
            <b-form-group>
              <label>Etapa:</label>
              <v-select
                v-model="etapaBusqueda"
                label="title"
                :options="etapas"
              />
            </b-form-group>
          </b-col>
          <b-col :cols="origenEstado === null ? 2 : 3">
            <b-form-group>
              <label>Técnico Asignado:</label>
              <v-select
                v-model="tecnicoSeleccionado"
                label="nombre"
                :options="tecnicosFiltrados"
              />
            </b-form-group>
          </b-col>
          <b-col cols="2">
            <b-form-group>
              <label>Fecha Inicio:</label>
              <b-input-group class="mb-1">
                <b-form-input
                  id="example-input"
                  v-model="fechaInicio"
                  type="text"
                  placeholder="YYYY-MM-DD"
                  autocomplete="off"
                  show-decade-nav
                />
                <b-input-group-append>
                  <b-form-datepicker
                    v-model="fechaInicio"
                    show-decade-nav
                    button-only
                    size="sm"
                    right
                    locale="en-US"
                    aria-controls="example-input"
                  />
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col cols="2">
            <b-form-group>
              <label>Fecha Fin:</label>
              <b-input-group class="mb-1">
                <b-form-input
                  id="fecha-fin"
                  v-model="fechaFin"
                  type="text"
                  placeholder="YYYY-MM-DD"
                  autocomplete="off"
                  show-decade-nav
                />
                <b-input-group-append>
                  <b-form-datepicker
                    v-model="fechaFin"
                    show-decade-nav
                    button-only
                    size="sm"
                    right
                    locale="en-US"
                    aria-controls="example-input"
                  />
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col cols="1">
            <br>
            <center>
              <table style="margin-left: -25px">
                <tr>
                  <td style="padding-top: 4px;">
                    <b-button
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      v-b-tooltip.hover.v-primary
                      class="btn-icon"
                      title="Buscar"
                      variant="gradient-primary"
                      @click="loadReport()"
                    >
                      <feather-icon icon="SearchIcon" />
                    </b-button>
                  </td>
                  <td style="padding-top: 4px;">
                    <div v-if="origenEstado === null || origenEstado.origen === 'ASIGNADAS'">
                      <componente-reasignacion
                        :tecnicos-filtrados="tecnicos"
                        :migraciones-seleccionadas="migracionesSeleccionadas"
                        @actualizar-datos="loadReport"
                      />
                    </div>
                  </td>
                  <td
                    v-if="usuario.company.enabledReport"
                    style="padding-top: 4px;"
                  >
                    <reporte-levantamiento-inicial
                      :fecha-fin="fechaFin"
                      :fecha-inicio="fechaInicio"
                      :localidad="localidad"
                      :etapa="etapaBusqueda"
                      :tecnico-asignado="tecnicoSeleccionado"
                      :origen-estado="origenEstado"
                    />
                  </td>
                  <td
                    v-if="usuario.company.enabledReport"
                    style="padding-top: 4px;"
                  >
                    <div v-if="origenEstado && origenEstado.origen === 'VERIFICADAS'">
                      <reporte-luminarias-instaladas
                        :fecha-inicio="fechaInicio"
                        :fecha-fin="fechaFin"
                        :tecnico-asignado="tecnicoSeleccionado"
                        :localidad="localidad"
                      />
                    </div>
                  </td>
                </tr>
              </table>
            </center>
          </b-col>
        </b-row>
        <!-- table -->
        <br>
        <b-row>
          <b-col cols="12">
            <!-- table -->
            <vue-good-table
              :columns=" columns"
              :rows="rows"
              :pagination-options="{
                enabled: true,
                perPage: pageLength
              }"
              :search-options="{
                enabled: true }"
              style-class="vgt-table condensed"
              :select-options="selectOptions()"
              @on-row-dblclick="onRowClick"
              @on-selected-rows-change="selectionChanged"
              @on-select-all="onSelectAll"
            >
              <template
                slot="table-row"
                slot-scope="props"
              >
                <span
                  v-if="props.column.field === 'usuarioInspeccion'"
                  style="font-size: 12px"
                >
                  {{ props.row.tracking[0].usuario.nombre }}
                  <!--button @click="actualizar(props.row)">Actualizar</button-->
                </span>
                <span v-if="props.column.field === 'fechaCreacion'">
                  {{ formatFecha(props.row.fechaCreacion) }}
                </span>
                <span
                  v-else-if="props.column.field === 'fechaAsignacion' && props.row.tracking[1] && props.row.tracking[1].horaFecha"
                >
                  {{ formatFecha(props.row.tracking[1].horaFecha) }}
                </span>
                <span
                  v-else-if="props.column.field === 'fechaInstalacion'"
                >
                  {{ formatFecha(getFechaInstalacion(props.row.tracking)) }}
                </span>
                <span
                  v-else-if="props.column.field === 'fechaMigracion'"
                >
                  {{ formatFecha(getFechaMigracion(props.row.tracking)) }}
                </span>
                <span
                  v-else-if="props.column.field === 'fechaFinalizado'"
                >
                  {{ formatFecha(getFechaFinalizado(props.row.tracking)) }}
                </span>
                <span
                  v-else-if="props.column.field === 'localidad'"
                  style="font-size: 12px"
                >
                  {{ props.row.levantamientoPunto.localidad.nombre }} ({{ props.row.levantamientoPunto.localidad.zona }})
                </span>
                <span
                  v-else
                  style="font-size: 13px"
                >
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </template>

              <!-- pagination -->
              <template
                slot="pagination-bottom"
                slot-scope="props"
              >
                <div class="d-flex justify-content-between flex-wrap">
                  <div class="d-flex align-items-center mb-0 mt-1">
                    <span class="text-nowrap">
                      Mostrar
                    </span>
                    <b-form-select
                      v-model="pageLength"
                      :options="['10', '15', '20']"
                      class="mx-1"
                      @input="value => props.perPageChanged({ currentPerPage: value })"
                    />
                    <span class="text-nowrap"> de {{ props.total }} registros </span>
                  </div>
                  <div>
                    <b-pagination
                      :value="1"
                      :total-rows="props.total"
                      :per-page="pageLength"
                      first-number
                      last-number
                      align="right"
                      prev-class="prev-item"
                      next-class="next-item"
                      class="mt-1 mb-0"
                      @input="value => props.pageChanged({ currentPage: value })"
                    >
                      <template #prev-text>
                        <feather-icon
                          icon="ChevronLeftIcon"
                          size="18"
                        />
                      </template>
                      <template #next-text>
                        <feather-icon
                          icon="ChevronRightIcon"
                          size="18"
                        />
                      </template>
                    </b-pagination>
                  </div>
                </div>
              </template>
            </vue-good-table>
          </b-col>
        </b-row>
      </div>
      <div>
        <b-modal
          v-model="showModalInspeccion"
          no-close-on-backdrop
          :title="`Detalle de Ticket No. ${datosTicket ? datosTicket.ticket : 'Detalle de Ticket'}`"
          size="lg"
          hide-footer
        >
          <form-levantamiento
            :punto-seleccionado="puntoIluminacion"
            :localizacion="userLocation"
            :tecnologias="tecnologias"
            :potencias="potencias"
            :tipos-poste="tiposPoste"
            :herrajes="herrajes"
            :tamano-brazos="tamanoBrazos"
            :tipo-conexiones="tipoConexiones"
            :tipo-lamparas="tiposLamparas"
            :propiedades="propiedades"
            :trabajos="trabajos"
            :tipo-postes-instalacion="tipoPostesInstalacion"
            :metro-triplex="metroTriplex"
            :clases-lampara="clasesLampara"
            :tamaños="tamaños"
            :dispositivos="dispositivos"
            :localidad="localidad"
            :localidades="localidades"
            :origen="'LEVANTAMIENTO'"
            @cerrar-dialogo-levantamiento="cerrarModalLevantamiento"
          />
        </b-modal>
      </div>
    </b-overlay>
  </div>
</template>

<script>
import {
  BButton,
  BCol,
  BFormDatepicker,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BInputGroup,
  BInputGroupAppend,
  BOverlay,
  BPagination,
  BRow, VBModal, VBPopover, VBTooltip,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import 'vue-good-table/dist/vue-good-table.css'
import { VueGoodTable } from 'vue-good-table'
import Ripple from 'vue-ripple-directive'
import { calcularFecha, sumarDiasFecha } from '@/utils/fechas'
import { getUsersByCompany } from '@/utils/usuarios'
import { getMigracionesSinDetalle } from '@/utils/migracionLuminarias'
import { getLocalidades } from '@/utils/localizacion'
import FormLevantamiento from '@/components/levantamiento/FormLevantamiento.vue'
import { getItem, getlistado } from '@/utils/catalogos'
import ReporteLevantamientoInicial from '@/utils/reportes/levantamientoInicial/reporteLevantamientoInicial.vue'
import ComponenteReasignacion from '@/components/migraciones/levantamientoInicial/componenteReasignacion.vue'
import ReporteLuminariasInstaladas from '@/utils/reportes/levantamientoInicial/reporteLuminariasInstaladas.vue'

export default {
  name: 'ListadoVue',
  components: {
    ReporteLuminariasInstaladas,
    ComponenteReasignacion,
    ReporteLevantamientoInicial,
    FormLevantamiento,
    BOverlay,
    BButton,
    BCol,
    BRow,
    BFormDatepicker,
    BInputGroup,
    BFormGroup,
    BInputGroupAppend,
    vSelect,
    BFormInput,
    VueGoodTable,
    BFormSelect,
    BPagination,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
    'b-popover': VBPopover,
    'b-modal': VBModal,
  },
  props: {
    origenEstado: {
      type: Object,
      default: () => null,
    },
    columnaFecha: {
      type: Object,
      default: () => null,
    },
  },
  data() {
    return {
      tecnicoSeleccionado: {
        nombre: '',
      },
      showOverlay: false,
      datosTicket: null,
      puntoIluminacion: null,
      showModalInspeccion: false,
      etapas: [
        { title: 'TODAS', value: '' },
        { title: 'Ingresados', value: 'INGRESADA' },
        { title: 'Asignados', value: 'ASIGNADA' },
        { title: 'En Instalación', value: 'INSTALACION' },
        { title: 'Migrados', value: 'MIGRADA' },
        { title: 'Finalizados', value: 'FINALIZADO' },
      ],
      etapaBusqueda: {
        title: 'TODAS',
        value: '',
      },
      localidad: {
        name: 'TODAS',
        id: 0,
      },
      localidades: [],
      fechaInicio: '',
      fechaFin: '',
      columns: [
        {
          label: 'No.',
          field: 'ticket',
          tdClass: 'text-center',
          thClass: 'vgt-center-align',
        },
        {
          label: 'Poste',
          field: 'numeroPoste',
          tdClass: 'text-center',
          thClass: 'vgt-center-align',
        },
        {
          label: 'Etapa',
          field: 'estado',
          tdClass: 'text-center',
          thClass: 'vgt-center-align',
        },
        {
          label: 'Usuario Inspección',
          field: 'usuarioInspeccion',
          tdClass: 'text-center',
          thClass: 'vgt-center-align',
        },
        {
          label: 'Técnico Asignado',
          field: 'usuario.nombre',
          tdClass: 'text-center',
          thClass: 'vgt-center-align',
        },
        {
          label: 'Fecha Ingreso',
          field: 'fechaCreacion',
          tdClass: 'text-center',
          thClass: 'vgt-center-align',
        },
        {
          label: 'Colonia (Zona)',
          field: 'localidad',
          tdClass: 'text-center',
          thClass: 'vgt-center-align',
        },
      ],
      usuario: JSON.parse(localStorage.getItem('userData')),
      columnasTickets: [],
      rows: [],
      migracionesData: [],
      pageLength: 15,
      tecnicos: [],
      tecnicosFiltrados: [],
      puntoLevantamiento: {},
      userLocation: {
        lat: 0,
        lng: 0,
      },
      tecnologias: [],
      potencias: [],
      tiposPoste: [],
      herrajes: [],
      tamanoBrazos: [],
      tipoConexiones: [],
      tiposLamparas: [],
      propiedades: [],
      tipoPostesInstalacion: [],
      clasesLampara: [],
      dispositivos: [],
      tamaños: [],
      trabajos: [],
      metroTriplex: {},
      catalogosCargados: false,
      migracionesSeleccionadas: [],
    }
  },
  watch: {
    origenEstado: {
      handler(newVal) {
        if (newVal === null) {
          // eslint-disable-next-line prefer-destructuring
          this.etapaBusqueda = this.etapas[0]
        } else if (newVal.origen === 'ASIGNADAS') {
          // eslint-disable-next-line prefer-destructuring
          this.etapaBusqueda = this.etapas[1]
        } else if (newVal.origen === 'INSTALACION') {
          // eslint-disable-next-line prefer-destructuring
          this.etapaBusqueda = this.etapas[2]
        } else if (newVal.origen === 'MIGRADAS') {
          // eslint-disable-next-line prefer-destructuring
          this.etapaBusqueda = this.etapas[3]
        } else if (newVal.origen === 'VERIFICADAS') {
          // eslint-disable-next-line prefer-destructuring
          this.etapaBusqueda = this.etapas[4]
        }
      },
      immediate: true,
    },
  },
  async created() {
    this.rows = []
    if (this.columnaFecha) {
      this.columns.push(this.columnaFecha)
    }
    const date = new Date()

    let mes = (date.getMonth() + 1).toString()
    if (mes.length === 1) mes = `0${mes}`
    let dia = date.getDate().toString()
    if (dia.length === 1) dia = `0${dia}`
    const parsedDate = `${date.getFullYear()}-${mes}-${dia}`

    this.fechaFin = parsedDate
    this.fechaInicio = parsedDate
    await this.loadReport()
    this.localidades = await getLocalidades(undefined, this.usuario.company.municipio)
    this.localidades.unshift({ name: 'TODAS', id: 0 })
    this.tecnicos = await getUsersByCompany(this.usuario.company.id)
    this.tecnicosFiltrados = this.tecnicos.filter(usuario => usuario.role === 'migracion').map(usuario => ({
      id: usuario.id,
      nombre: `${usuario.firstName} ${usuario.firstSurname}`,
      role: usuario.role,
    }))
    this.tecnicos.map(usuario => {
      // eslint-disable-next-line no-param-reassign
      usuario.nombre = `${usuario.firstName} ${usuario.firstSurname}`
      return usuario
    })
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        // eslint-disable-next-line no-return-assign
        pos => (
          this.localization = {
            latitude: pos.coords.latitude,
            longitude: pos.coords.longitude,
          }),
        error => {
          console.error('Error Obteniendo Latitud y Longitud!', error)
          this.localization = null
        },
      )
    }
    this.showOverlay = false
  },
  methods: {
    getFechaInstalacion(tracking) {
      if (!tracking || !Array.isArray(tracking)) return null
      const instalacion = tracking.find(entry => entry.estado === 'INSTALACION')
      return instalacion ? instalacion.horaFecha : null
    },
    getFechaMigracion(tracking) {
      if (!tracking || !Array.isArray(tracking)) return null
      const migracion = tracking.find(entry => entry.estado === 'MIGRADA')
      return migracion ? migracion.horaFecha : null
    },
    getFechaFinalizado(tracking) {
      if (!tracking || !Array.isArray(tracking)) return null

      const finalizado = tracking.find(entry => entry.estado === 'VERIFICACION')
      return finalizado ? finalizado.horaFecha : null
    },
    selectOptions() {
      return {
        enabled: true,
        selectOnCheckboxOnly: true,
        selectionInfoClass: 'custom-class',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
        disableSelectInfo: true,
        selectAllByGroup: true,
      }
    },
    selectionChanged(params) {
      this.migracionesSeleccionadas = params.selectedRows
    },
    onSelectAll(params) {
      this.migracionesSeleccionadas = params.selectedRows
    },
    formatFecha(row) {
      return calcularFecha(row)
    },
    async onRowClick(params) {
      this.loadReport()
      if (!this.catalogosCargados) {
        await this.cargaInformacion()
        this.catalogosCargados = true
      }
      this.puntoIluminacion = {
        id: params.row.idPuntoIluminacion,
      }
      this.showModalInspeccion = true
    },
    cerrarModalLevantamiento() {
      this.showModalInspeccion = false
      this.loadReport()
    },
    async cargaInformacion() {
      try {
        const [
          tecnologias,
          potencias,
          tiposPoste,
          herrajes,
          tamanoBrazos,
          tipoConexiones,
          tiposLamparas,
          propiedades,
          trabajos,
          tipoPostesInstalacion,
          metroTriplex,
          clasesLampara,
          dispositivos,
          tamaños,
          allLocalidades,
        ] = await Promise.all([
          getlistado(4, 'Tecnología Luminarias', false, this.usuario),
          getlistado(25, 'Potencias', false, this.usuario),
          getlistado(29, 'Tipos de Poste', false, this.usuario),
          getlistado(26, 'Herrajes', false, this.usuario),
          getlistado(14, 'Tipo Brazo', false, this.usuario),
          getlistado(28, 'Conexiones', false, this.usuario),
          getlistado(9, 'Tipos de Lámparas', false, this.usuario),
          getlistado(6, 'Propiedades', false, this.usuario),
          getlistado(30, 'Trabajos Levantamiento', false, this.usuario),
          getlistado(1, 'Tipos de Poste', false, this.usuario),
          getItem(10, 25),
          getlistado(3, 'Clases de Lámpara', false, this.usuario),
          getlistado(31, 'Dispositivos de Lámpara', false, this.usuario),
          getlistado(32, 'Tamaños de Accesorios', false, this.usuario),
        ])

        potencias.sort((a, b) => a.valor - b.valor)

        this.tecnologias = tecnologias
        this.potencias = potencias
        this.tiposPoste = tiposPoste
        this.herrajes = herrajes
        this.tamanoBrazos = tamanoBrazos
        this.tipoConexiones = tipoConexiones
        this.tiposLamparas = tiposLamparas
        this.propiedades = propiedades
        this.trabajos = trabajos
        this.tipoPostesInstalacion = tipoPostesInstalacion
        this.metroTriplex = metroTriplex
        this.clasesLampara = clasesLampara
        this.dispositivos = dispositivos
        this.tamaños = tamaños
        this.allLocalidades = allLocalidades

        this.items = []
        if (this.localidades === null) this.localidades = []
        if (this.localidades.length > 0) {
          this.showOverlay = false
        }
      } catch (err) {
        console.error(`Error en cargar ${this.catalogoName}`, err)
        this.localidades = []
        this.items = []
      }
    },
    async loadReport() {
      this.showOverlay = true
      const fechaInicio = new Date(this.fechaInicio)
      const fechaFin = sumarDiasFecha(new Date(this.fechaFin), 1)

      let filter = {}
      if (this.origenEstado === null) {
        filter = {
          where: {
            and: [
              { companyId: this.usuario.company.id },
              { fechaCreacion: { gte: fechaInicio.toISOString() } },
              { fechaCreacion: { lte: fechaFin.toISOString() } },
            ],
          },
          order: ['fechaCreacion DESC'],
        }

        if (this.etapaBusqueda && this.etapaBusqueda.value !== '') {
          filter.where.and.push({ estado: this.etapaBusqueda.value })
        }
      } else if (this.origenEstado.origen === 'VERIFICADAS') {
        filter = {
          where: {
            and: [
              { companyId: this.usuario.company.id },
              { estado: this.origenEstado.estado },
              {
                'seguimiento.tipo': this.origenEstado.tipo,
              },
              {
                'seguimiento.fechaHora': { gte: fechaInicio.toISOString() },
              },
              {
                'seguimiento.fechaHora': { lte: fechaFin.toISOString() },
              },
            ],
          },
          order: ['ticket DESC'],
        }
      } else {
        filter = {
          where: {
            and: [
              { companyId: this.usuario.company.id },
              { estado: this.origenEstado.estado },
              {
                'tracking.estado': this.origenEstado.estado, // Filtro por estadoTracking
              },
              {
                'tracking.horaFecha': { gte: fechaInicio.toISOString() },
              },
              {
                'tracking.horaFecha': { lte: fechaFin.toISOString() },
              },
            ],
          },
          order: ['ticket DESC'],
        }
      }
      if (this.localidad && this.localidad.id > 0) {
        filter.where.and.push({ 'levantamientoPunto.localidad.id': this.localidad.id })
      }
      if (this.tecnicoSeleccionado && this.tecnicoSeleccionado.id) {
        filter.where.and.push({ 'usuario.id': this.tecnicoSeleccionado.id })
      }

      const data = await getMigracionesSinDetalle(filter)
      // eslint-disable-next-line no-restricted-syntax
      // item.ruta = await getItem(8, item.rutaId)

      this.showOverlay = false
      this.migracionesData = data
      this.rows = data

      this.showOverlay = false
    },
  },
}
</script>
<style scoped>
@keyframes fade {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}

.fade {
  animation: fade 1s ease-in-out infinite;
}
</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style>
@media (min-width: 576px) {
  .modal-dialog {
    max-width: 90% !important;
  }
}
</style>
